<!--
// nuxt-ui/components/layout/header/LiveButtonKok.vue
-->
<script setup lang="ts">
import TwitchIcon from "~/nuxt-ui/components/Shared/icons/TwitchIcon.vue";
import BaseNuxtLink from "~/nuxt-ui/components/base/baseNuxtLink.vue";
import { useTwitch } from "~/nuxt-ui/Shared/composables/twitch";
import { $TWITCH_INTERVAL_REFRESH } from "~/src/twitch/domain/services/twitchService";
import { isFutureDate, isPastDate } from "~/src/Shared/utils";

type Props = {
  full?: boolean;
};

defineProps<Props>();

const { getTwitchLiveInfoFromRegion } = useTwitch();

const intervalId = ref<NodeJS.Timeout>();
const spainIsLive = ref<boolean>(false);
const americasIsLive = ref<boolean>(false);

const isSunday = isPastDate("2024-11-24T10:00:00Z") && isFutureDate("2024-11-24T22:00:00Z");
const isMonday = isPastDate("2024-11-25T10:00:00Z") && isFutureDate("2024-11-26T08:00:00Z");

const updateTwitchStatus = async (): Promise<void> => {
  if (isSunday) {
    const spainTwitch = await getTwitchLiveInfoFromRegion("espana");
    if (spainTwitch) {
      spainIsLive.value = spainTwitch.live;
    }
  } else if (isMonday) {
    const americasTwitch = await getTwitchLiveInfoFromRegion("americas");
    if (americasTwitch) {
      americasIsLive.value = americasTwitch.live;
    }
  }
};

onMounted(async () => {
  await nextTick();

  await updateTwitchStatus();
  intervalId.value = setInterval(async function () {
    await updateTwitchStatus();
  }, $TWITCH_INTERVAL_REFRESH);
});

const twitchLink = computed(() => {
  if (spainIsLive.value && isSunday) {
    return "https://www.twitch.tv/kingsleague";
  } else if (americasIsLive.value && isMonday) {
    return "https://www.twitch.tv/kingsleagueamericas";
  } else {
    return "";
  }
});
</script>

<template>
  <base-nuxt-link v-if="twitchLink" text-size="text-sm" :full-width="full" :to="twitchLink" target="_blank">
    <span class="flex gap-2 items-center">
      <span class="relative flex h-2 w-2" v-if="spainIsLive || americasIsLive">
        <span
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"
        ></span>
        <span class="relative inline-flex rounded-full h-2 w-2 bg-red-600"></span>
      </span>
      <span class="flex items-center gap-1">
        <twitch-icon width="10"></twitch-icon>{{ $t("header.liveBtn") }}
      </span>
    </span>
  </base-nuxt-link>
</template>

<style scoped></style>
