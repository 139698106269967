<!--
// nuxt-ui/components/layout/header/ButtonsNavbar.vue
-->
<script setup lang="ts">
import BaseNuxtLink from "~/nuxt-ui/components/base/baseNuxtLink.vue";
import TicketIcon from "~/nuxt-ui/components/Shared/icons/TicketIcon.vue";
import ShirtIcon from "~/nuxt-ui/components/Shared/icons/ShirtIcon.vue";
import TwitchIcon from "~/nuxt-ui/components/Shared/icons/TwitchIcon.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { isPastDate } from "~/src/Shared/utils";
import LiveButtonKok from "~/nuxt-ui/components/layout/header/LiveButtonKok.vue";

const localePath = useLocalePath();
const { isKings, isQueens } = useWebContext();
const { isAmericas, isSpain, isItaly, isWorldCup, isNations, isBrazil, isKokNations } = useRegions();
const { region } = useCompetitionStore();

type TicketsButtonData = {
  url: string;
  target: "_blank" | "_self";
};

type Props = {
  live?: boolean;
  store?: boolean;
  tickets?: boolean;
  full?: boolean;
  storeUrl?: string;
  liveUrl?: string;
  twitchIsLive?: boolean;
};

withDefaults(defineProps<Props>(), {
  live: true,
});

const ticketsUrl = computed((): TicketsButtonData => {
  const specificUrls = {
    kings: {
      spain: "https://www.entradas.com/artist/kings-league/?affiliate=KQL",
      americas: "https://www.passline.com/sitio/kings-y-queens-league-965453108",
      worldCup: "https://www.ticketmaster.com.mx/search?q=kings+world+cup",
    },
    queens: {
      spain: "https://www.entradas.com/artist/kings-league/?affiliate=KQL",
      americas: "https://www.passline.com/sitio/kings-y-queens-league-965453108",
    },
  };

  if (isKings) {
    if (specificUrls.kings.spain && (isSpain || region?.slug === "espana")) {
      return { url: specificUrls.kings.spain, target: "_blank" };
    }
    if (specificUrls.kings.americas && (isAmericas || region?.slug === "americas")) {
      return { url: specificUrls.kings.americas, target: "_blank" };
    }
    if (specificUrls.kings.worldCup && (isWorldCup || region?.slug === "world-cup")) {
      return { url: specificUrls.kings.worldCup, target: "_blank" };
    }
  } else if (isQueens) {
    if (specificUrls.queens.spain && (isSpain || region?.slug === "espana")) {
      return { url: specificUrls.queens.spain, target: "_blank" };
    }
    if (specificUrls.queens.americas && (isAmericas || region?.slug === "americas")) {
      return { url: specificUrls.queens.americas, target: "_blank" };
    }
  }

  return {
    url: localePath({
      name: "region-Tickets",
      params: {
        region: region?.slug,
      },
    }),
    target: "_self",
  };
});

const showTicketsItalyNations = ref<boolean>(!!isPastDate("2024-11-18T20:50:00Z"));

onMounted(() => {
  showTicketsItalyNations.value = !!isPastDate("2024-11-18T20:50:00Z");
});
</script>

<template>
  <base-nuxt-link
    v-if="live && liveUrl && !isKokNations"
    text-size="text-sm"
    :full-width="full"
    :to="liveUrl"
    target="_blank"
  >
    <span class="flex gap-2 items-center">
      <span class="relative flex h-2 w-2" v-if="twitchIsLive">
        <span
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"
        ></span>
        <span class="relative inline-flex rounded-full h-2 w-2 bg-red-600"></span>
      </span>
      <span class="flex items-center gap-1">
        <twitch-icon width="10"></twitch-icon>{{ $t("header.liveBtn") }}
      </span>
    </span>
  </base-nuxt-link>
  <live-button-kok v-else-if="isKokNations" full></live-button-kok>

  <base-nuxt-link
    v-if="tickets && !isItaly && !isNations && !isWorldCup && !isBrazil"
    text-size="text-sm"
    :full-width="full"
    :to="ticketsUrl.url"
    :target="ticketsUrl.target"
    no-prefetch
  >
    <span class="flex items-center gap-2">
      <ticket-icon width="12"></ticket-icon>{{ $t("header.tickets") }}
    </span>
  </base-nuxt-link>

  <!--  tickets for kwc nations & italy-->
  <base-nuxt-link
    v-if="tickets && showTicketsItalyNations && (isItaly || isNations)"
    text-size="text-sm"
    :full-width="full"
    to="https://www.ticketone.it/artist/kings-world-cup-nations-finals/kings-world-cup-nations-final-3765195/?affiliate=KOS"
    target="_blank"
    no-prefetch
  >
    <span class="flex items-center gap-2">
      <ticket-icon width="12"></ticket-icon>{{ $t("header.tickets") }}
    </span>
  </base-nuxt-link>

  <!--  tickets for kwc nations & italy-->

  <base-nuxt-link
    v-if="store && isKings && !isSpain"
    text-size="text-sm"
    :full-width="full"
    emphasis
    :to="localePath({ name: 'StoresLanding' })"
    target="_blank"
    no-prefetch
  >
    <span class="flex items-center gap-2">
      <shirt-icon width="14"></shirt-icon>
      {{ $t("header.store") }}
    </span>
  </base-nuxt-link>

  <base-nuxt-link
    v-if="store && isKings && isSpain"
    text-size="text-sm"
    :full-width="full"
    emphasis
    :to="region.storeUrl"
    target="_blank"
    no-prefetch
  >
    <span class="flex items-center gap-2">
      <shirt-icon width="14"></shirt-icon>
      {{ $t("header.store") }}
    </span>
  </base-nuxt-link>

  <base-nuxt-link
    v-if="store && isQueens && !isAmericas"
    text-size="text-sm"
    :full-width="full"
    emphasis
    :to="storeUrl"
    target="_blank"
  >
    <span class="flex items-center gap-2">
      <shirt-icon width="14"></shirt-icon>
      {{ $t("header.store") }}
    </span>
  </base-nuxt-link>
</template>

<style scoped></style>
